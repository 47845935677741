/// <reference path="./main.d.ts" />

import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/reset.css'
import 'element-ui/lib/theme-chalk/index.css'
import locale from '@/locale/ru.json'
import { Model } from 'vue-api-query'
import lang from 'element-ui/lib/locale/lang/ru-RU.js'
import ElementLocaleLib from 'element-ui/lib/locale'
import ElementLocaleSrc from 'element-ui/src/locale'
import 'vue-draggable-responsive/dist/vue-draggable-responsive.css'
import Portal from './Portal.vue'
import axios from 'axios'
import 'moment/locale/ru'
import VueMask from 'v-mask'

ElementLocaleLib.use(lang)
ElementLocaleSrc.use(lang)
Vue.use(Element)
const moment = require('moment')
require('moment/locale/ru')
Vue.use(require('vue-moment'), {
  moment
})
Vue.use(VueMask, {
  placeholders: {
    Я: /[а-яА-Я]/
  }
})

Vue.mixin({
  methods: {
    generateGuid () {
      let d = new Date().getTime()
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now()
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      })
    },
    toCamelCase (s: string): string {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    },
    toSnakeCase (s: string): string {
      return s.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    }
  }
})

Vue.prototype.$store = {
  getters: {
    'Authorization/roleId': 0,
    'Authorization/userId': 0,
    'Authorization/userAttributeData': (v) => {
      return null
    }
  }
}

Vue.prototype.$http = axios.create()
config.is_portal = true
Model.$http = Vue.prototype.$http
Vue.prototype.$config = config
Vue.prototype.$locale = locale
Vue.config.productionTip = false

new Vue({
  render: h => h(Portal),
  mounted () {
    document.title = this.$config.title
  }
}).$mount('#app')
